import React, { FC, useEffect } from 'react';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import filter from '../../store/filter';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import overall from '../../store/overall';

import api from '../../api';

import SidebarWrapper from './wrapper';
import ArrowLink from '../arrow-link';
import ArrowList from '../arrow-list';
import DeepFilter from '../deep-filter';

const Sidebar: FC<{
  isLoading: boolean;
  handleLoading?: (isLoading: boolean) => void;
}> = ({
  isLoading,
  handleLoading,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { catLevel1, catLevel2, catLevel3, vType } = useParams<{
    catLevel1?: string;
    catLevel2?: string;
    catLevel3?: string;
    vType: string
  }>();
  const categoriesList = filter.currentCategories;
  const catLevels = [catLevel1, catLevel2, catLevel3];
  const existLevels = catLevels.filter(Boolean);

  const linkPrefix = `/${overall.currentPrefix}${
    existLevels.length ? `${existLevels.join('/')}/` : ''
  }`;
  const backLink =
    existLevels.length > 1 && existLevels[0] !== 'wiper-blades' && existLevels[0] !== 'compressors'
      ? `/${overall.currentPrefix}${existLevels.slice(0, -1).join('/')}`
      : '/';

  useEffect(() => {
    const prefix = storeCurrentVehicle.current ?
      `${storeCurrentVehicle.current.vehicleType}/${storeCurrentVehicle.current.id}`
      : vType;
    if (overall.prefix !== prefix) {
      overall.setPrefix(prefix);
      history.push(`/${overall.currentPrefix}${existLevels.join('/')}`);
    }
  }, [storeCurrentVehicle.current]);

  useEffect(() => {
    if (overall.prefix) {
      const [type, carId] = overall.prefix.split('/');

      handleLoading(true);

      api
        .categories
        .getList({
          carId,
          vType: type,
        })
        .then(res => res.json())
        .then(res => {
          if (res.data && res.data.length) {
            const filteredList = res.data.sort((a, b) =>
              a.title.localeCompare(b.title),
            );
            filter.setCategoryList(filteredList);

            if (filter.initialLevels?.length > 0) {
              filter.setCurrentCategory(filter.initialLevels);
            }
          }
        })
        .catch(err => console.error(err))
        .then(() => handleLoading(false));
    }
  }, [overall.prefix]);

  useEffect(() => {
    filter.setCurrentCategory(catLevels.filter(Boolean));
  }, [catLevel3, catLevel2, catLevel1]);

  const handleRemoveFilters = () => {
    history.push({
      search: null,
    });
  };

  // Redirect if wiper blades category
  if (catLevel1 === 'wiper-blades' && !catLevel2) {
    return <Redirect to={`/${overall.currentPrefix}wiper-blades/all`} />;
  }

  if (catLevel1 === 'compressors' && !catLevel2) {
    return <Redirect to={`/${overall.currentPrefix}compressors/all-compressors`} />;
  }

  if (isLoading) {
    return (
      <SidebarWrapper>
        <div className="page-fmp__filter-skeleton">
          <div className="page-fmp__filter-skeleton-title" />
          <div>
            {
              [...Array(5)].map((innerItem, innerIndex) => (
                <div
                  key={`filter-skeleton-item-${innerIndex}`}
                  className="page-fmp__filter-skeleton-val"
                />
              ))
            }
          </div>
        </div>
      </SidebarWrapper>
    );
  }

  return (
    <SidebarWrapper isFiltered={!!search}>
      {
        // Remove Filter Button
        search ? (
          <div className="page-fmp__filter-clear">
            <button
              className="page-fmp__vehicle-link page-fmp__vehicle-link_remove"
              onClick={handleRemoveFilters}
              type="button"
            >{ __('Remove all filters') }</button>
          </div>
        ) : null
      }

      {
        // Back button
        catLevel1 ? (
          <div className="page-fmp__filter-back" onClick={() => filter.resetInitialLevels()}>
            <ArrowLink title={ __('Back') } link={backLink} reverseArrow />
          </div>
        ) : null
      }

      {
        // Categories hierarchy
        categoriesList?.length > 0 &&
        categoriesList.reduce((acc, curr) => acc + curr.parts, 0) !== 0 ? (
          <ArrowList
            list={categoriesList}
            title={filter.currentCategory?.title}
            levels={existLevels}
            linkPrefix={linkPrefix}
          />
          ) : null
      }

      {
        // Filter for products list
        catLevel2 && filter.currentCategory?.parentId !== 0 ? (
          <DeepFilter />
        ) : null
      }
    </SidebarWrapper>
  );
};

export default observer(Sidebar);
