const containerCloseClass = 'country-detect-container_closed';

const switchCountry = (handle): void => {
  try {
    sessionStorage.setItem('selected-country', handle);
    window.location.href = `/${handle}`;
  } catch (e) {
    // Ignore sessionStorage errors
  }
};

const cancel = (): void => {
  try {
    const container = document.querySelector('.js-country-detect');
    const handle = document.documentElement.dataset.currentSite;
    sessionStorage.setItem('selected-country', handle);
    container.classList.add(containerCloseClass);
  } catch (e) {
    // Ignore sessionStorage errors
  }
};

const init = (): void => {
  document.addEventListener('afterBlitzInjectAll', () => {
    const container = document.querySelector('.js-country-detect');
    if (!container) {
      return;
    }

    try {
      const selectedCountry = sessionStorage.getItem('selected-country');
      if (selectedCountry) {
        return;
      }

      // Show
      container.classList.remove(containerCloseClass);

      const switchButton = document.querySelector('[data-country-detect-switch]');
      switchButton.addEventListener('click', () => {
        const handle = (switchButton as HTMLElement).dataset.countryDetectSwitch;
        switchCountry(handle);
      });

      const select = document.querySelector('.js-country-detect-select');
      select.addEventListener('change', () => {
        const handle = (select as HTMLSelectElement).value;
        switchCountry(handle);
      });

      const cancelButton = document.querySelector('.js-country-detect-cancel');
      cancelButton.addEventListener('click', cancel);
    } catch (e) {
      // Ignore sessionStorage errors
    }
  });
};

export default {
  init,
};
