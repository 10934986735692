import React, { FC } from 'react';

import ArrowLink from '../arrow-link';
import __ from '../../../../utils/translation';

const DetailBack: FC<{
  backLink?: string;
}> = ({ backLink = '/' }) => {

  return (
    <div className="catalog-detail__back">
      <ArrowLink
        title={__('Back')}
        link={backLink}
        reverseArrow
      />
    </div>
  );
};

export default DetailBack;
