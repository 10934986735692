import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import cn from 'classnames';

import { useResize } from '../../hooks';
import __ from '../../../../utils/translation';

import Icon from '../icon';
import DetailHider from './hider';

let debounce = null;

const DetailInformation: FC<{
  markup: string[],
}> = ({
  markup,
}) => {
  const isDesktop = useResize();
  const title = __('General product information');
  const [isMore, setIsMore] = useState(false);
  const [isFull, setIsFull] = useState(true);
  const contentText = useRef<HTMLDivElement>(null);

  if (markup?.length === 0) {
    return null;
  }

  const toggleMore = () => {
    setIsMore(!isMore);
  };

  const calculateCurrentHeight = useCallback(() => {
    clearTimeout(debounce);

    debounce = setTimeout(() => {
      if (contentText?.current) {
        const { height } = contentText.current.getBoundingClientRect();

        setIsFull(height < 512);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    calculateCurrentHeight();
    window.addEventListener('resize', calculateCurrentHeight);

    return () => {
      window.removeEventListener('resize', calculateCurrentHeight);
    };
  }, []);

  if (!isDesktop) {
    return (
      <DetailHider title={title} isText>
        <div className="catalog-detail__expander-wrap">
          <div
            className="catalog-detail__expander-inner"
            dangerouslySetInnerHTML={{ __html: markup.join('') }}
            ref={contentText}
          />
        </div>
      </DetailHider>
    );
  }

  return (
        <div className="catalog-detail__block">
            <div className="catalog-detail__block-title">
                <h3 className="heading heading_h3">{ title }</h3>
            </div>
            <div className="catalog-detail__block-content">
                <div
                    className={cn(
                      'catalog-detail__expander',
                      { 'catalog-detail__expander_more': isMore },
                    )}
                >
                    <div className="catalog-detail__expander-wrap">
                        <div
                            className="catalog-detail__expander-inner"
                            dangerouslySetInnerHTML={{ __html: markup.join('') }}
                            ref={contentText}
                        />
                    </div>

                    {
                        !isFull
                          ? <div className="catalog-detail__expander-toggler">
                                <button
                                    className="catalog-detail__expander-toggler-btn"
                                    type="button"
                                    onClick={toggleMore}
                                >
                            <span className="catalog-detail__expander-toggler-btn-icon">
                                <Icon
                                    name="down-arrow"
                                    className="catalog-detail__expander-toggler-btn-svg"
                                />
                            </span>
                                    <span
                                        className="catalog-detail__expander-toggler-btn-text"
                                    >{ isMore ? __('Show less') : __('Show more') }</span>
                                </button>
                                <div
                                    className="catalog-detail__expander-toggler-line"
                                    aria-hidden="true"
                                />
                            </div>
                          : null
                    }
                </div>
            </div>
        </div>
  );
};

export default DetailInformation;
