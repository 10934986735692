let bttButton: HTMLAnchorElement;
let bttObserver: IntersectionObserver;

const handleClick = (e: Event): void => {
    e.preventDefault();

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const events = (): void => {
    bttButton.addEventListener('click', handleClick);
};

const handleObserve = (e): void => {
    bttButton.classList.toggle('back-to-top__btn_visible', !e[0].isIntersecting);
};

const initObserver = (): void => {
    let fakeElement = document.createElement('div');

    fakeElement.className = 'back-to-top-observe';
    document.body.prepend(fakeElement);

    bttObserver = new IntersectionObserver(handleObserve);
    bttObserver.observe(fakeElement);
};

const init = (): void => {
    bttButton = document.querySelector('.back-to-top__btn');

    if (bttButton) {
        initObserver();
        events();
    }
};

export default {
    init,
};
