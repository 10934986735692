let langSwitch: HTMLElement;
let langList: HTMLUListElement;
let langButton: HTMLButtonElement;

const outsideClick = (e: Event): void => {
    const target = e.target as HTMLElement;

    if (!target.classList.contains('lang-switch__btn')) {
        langSwitch.classList.remove('lang-switch_open');
    }
};

const toggleDD = (): void => {
    langSwitch.classList.toggle('lang-switch_open');
};

const toggleShadowClasses = (): void => {
    langSwitch.classList.toggle('lang-switch_top', langList.scrollTop > 16);
    langSwitch.classList.toggle('lang-switch_bottom', langList.scrollTop < (langList.scrollHeight - langList.offsetHeight - 16));
};

const events = (): void => {
    langList.addEventListener('scroll', toggleShadowClasses, { passive: true });
    langButton.addEventListener('click', toggleDD, { passive: true });
    document.addEventListener('click', outsideClick, { passive: true });
};

const init = (): void => {
    langSwitch = document.querySelector('.lang-switch');

    if (langSwitch) {
        langList = langSwitch.querySelector('.lang-switch__dd-list');
        langButton = langSwitch.querySelector('.lang-switch__btn');

        events();
    }
};

export default  {
    init,
};
