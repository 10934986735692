import React, { FC } from 'react';
import { Filter } from '../../../../../../store/filters/models';
import { observer } from 'mobx-react-lite';
import Checkbox from '../../../checkbox';
import storeCurrentFilters from '../../../../../../store/filters';
import __ from '../../../../../../../../utils/translation';

interface AdditionalFilterGroupProps {
  label: string;
  filters: Filter[];
  filterAlias: string;
}

const AdditionalFilterGroup: FC<AdditionalFilterGroupProps> = ({ filterAlias, filters, label }) => {
  const toggleFilter = (filter: Filter) => {
    storeCurrentFilters.setCurrentFilters(filter, filterAlias, true);
  };

  const checkboxes = filters.map(filter => {
    return (
      <Checkbox
        key={`checkbox-${filter.id} ${label}`}
        name={label}
        onClick={toggleFilter}
        value={filter}
        id={`${filter.id}${label}`}
        label={filter.label}
      />
    );
  });

  return (
    <div className="additional-filter-group">
      <div className="where-to-buy__sidebar-filter-label">{__(label)}</div>
      {checkboxes}
    </div>
  );
};

export default observer(AdditionalFilterGroup);
