const specificationsListByLang = (item) => {
  if (item?.criteria?.length === 0) {
    return [];
  }

  return item.criteria.map(({ tid, label, label_tid, val }) => ({
    id: tid,
    label,
    labelTid: label_tid,
    value: val,
  }));
};

export default specificationsListByLang;
