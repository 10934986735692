import { makeAutoObservable, runInAction } from 'mobx';
import Map from './model';

const storeMap = makeAutoObservable<Map>({
  instance: null,
  clusterer: null,
  updateOnMove: true,
  currentCity: null,
  setUpdateOnMove(updateOnMove) {
    runInAction(() => {
      storeMap.updateOnMove = updateOnMove;
    });
  },
  setClusterer(clusterer) {
    runInAction(() => {
      storeMap.clusterer = clusterer;
    });
  },
  setInstance(instance) {
    runInAction(() => {
      storeMap.instance = instance;
    });
  },
  setCurrentCity(city) {
    runInAction(() => {
      storeMap.currentCity = city;
    });
  }
});

export default storeMap;
