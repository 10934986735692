import React, { FC } from 'react';

import { Replaces } from './item';

const PartsItemReplaces: FC<{
  title: string;
  isReplaced?: boolean;
  items?: Replaces[];
}> = ({
  title,
  isReplaced = false,
  items = [],
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="parts-list__item-replaces">
      <svg className="parts-list__item-replaces-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M22.409 11.9999C22.409 17.7487 17.7487 22.409 11.9999 22.409V23.409C18.301 23.409 23.409 18.301 23.409 11.9999H22.409ZM11.9999 22.409C6.25113 22.409 1.59082 17.7487 1.59082 11.9999H0.59082C0.59082 18.301 5.69884 23.409 11.9999 23.409V22.409ZM1.59082 11.9999C1.59082 6.25113 6.25113 1.59082 11.9999 1.59082V0.59082C5.69884 0.59082 0.59082 5.69884 0.59082 11.9999H1.59082ZM11.9999 1.59082C17.7487 1.59082 22.409 6.25113 22.409 11.9999H23.409C23.409 5.69884 18.301 0.59082 11.9999 0.59082V1.59082Z" fill="#ccc"/>
        {
          isReplaced
            ? <path d="M11.0913 15.5716C11.0913 15.4436 11.1165 15.3169 11.1655 15.1988C11.2144 15.0806 11.2862 14.9732 11.3766 14.8827C11.4671 14.7922 11.5745 14.7205 11.6927 14.6715C11.8109 14.6226 11.9376 14.5974 12.0655 14.5974C12.1934 14.5974 12.3201 14.6226 12.4383 14.6715C12.5565 14.7205 12.6639 14.7922 12.7543 14.8827C12.8448 14.9732 12.9166 15.0806 12.9655 15.1988C13.0145 15.3169 13.0397 15.4436 13.0397 15.5716C13.0397 15.8299 12.937 16.0777 12.7543 16.2604C12.5716 16.4431 12.3239 16.5457 12.0655 16.5457C11.8071 16.5457 11.5593 16.4431 11.3766 16.2604C11.1939 16.0777 11.0913 15.8299 11.0913 15.5716ZM12.552 13.2981H11.5779C11.5349 13.2981 11.4938 13.2811 11.4633 13.2509C11.4328 13.2206 11.4156 13.1796 11.4153 13.1366V7.61665C11.4156 7.57344 11.433 7.5321 11.4636 7.50164C11.4943 7.47119 11.5357 7.4541 11.5789 7.4541H12.552C12.5952 7.45439 12.6366 7.47176 12.667 7.50241C12.6975 7.53307 12.7146 7.57453 12.7146 7.61774V13.1356C12.7143 13.1785 12.697 13.2195 12.6666 13.2498C12.6361 13.28 12.595 13.297 12.552 13.297V13.2981Z" fill="#DC0032"/>
            : <path d="M17.8271 10.452L15.4266 8.16793C15.3707 8.11469 15.3042 8.07246 15.2311 8.04364C15.158 8.01483 15.0797 8 15.0005 8C14.8407 8 14.6875 8.06041 14.5745 8.16793C14.4615 8.27546 14.398 8.4213 14.398 8.57336C14.398 8.72543 14.4615 8.87126 14.5745 8.97879L15.9547 10.2864H8.99946C8.8403 10.2864 8.68766 10.3466 8.57512 10.4537C8.46257 10.5608 8.39935 10.706 8.39935 10.8575C8.39935 11.0089 8.46257 11.1542 8.57512 11.2612C8.68766 11.3683 8.8403 11.4285 8.99946 11.4285H17.401C17.5195 11.4279 17.6351 11.394 17.7334 11.331C17.8316 11.268 17.9081 11.1787 17.9531 11.0745C17.999 10.9705 18.0116 10.8558 17.9892 10.7451C17.9667 10.6343 17.9103 10.5323 17.8271 10.452V10.452ZM15.0005 12.5705H6.59902C6.48054 12.5711 6.36489 12.605 6.26664 12.668C6.16839 12.731 6.09194 12.8203 6.04692 12.9246C6.00096 13.0286 5.98841 13.1432 6.01084 13.2539C6.03327 13.3647 6.08967 13.4667 6.17294 13.547L8.57338 15.8311C8.62917 15.8846 8.69554 15.9271 8.76867 15.9561C8.8418 15.9851 8.92023 16 8.99946 16C9.07868 16 9.15712 15.9851 9.23024 15.9561C9.30337 15.9271 9.36975 15.8846 9.42553 15.8311C9.48178 15.778 9.52643 15.7149 9.55689 15.6453C9.58736 15.5757 9.60304 15.501 9.60304 15.4257C9.60304 15.3503 9.58736 15.2756 9.55689 15.2061C9.52643 15.1365 9.48178 15.0733 9.42553 15.0202L8.04528 13.7126H15.0005C15.1597 13.7126 15.3123 13.6524 15.4249 13.5453C15.5374 13.4383 15.6007 13.293 15.6007 13.1416C15.6007 12.9901 15.5374 12.8449 15.4249 12.7378C15.3123 12.6307 15.1597 12.5705 15.0005 12.5705Z" fill="#DC0032"/>
        }
      </svg>
      <div className="parts-list__item-replaces-title">{ title }:</div>
      {
        items.map(({ url, name }) => (
          <div
            key={`replaces-item-${name}`}
            className="parts-list__item-replaces-item"
          >&nbsp;
            <a
              href={ url }
              className="parts-list__item-replaces-link"
              target="_blank"
            >{ name }</a>
          </div>
        ))
      }
    </div>
  );
};

export default PartsItemReplaces;
