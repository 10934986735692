import __ from '../../utils/translation';

// Paginator Per Page Items
export const PER_PAGE = 15;

// Banner Anchors
export const BANNER_ANCHORS = [
  {
    icon: 'alert',
    title: __('General information'),
    hash: 'generalInformation',
  },
  {
    icon: 'hammer',
    title: __('Installation &amp; Fault finding manuals'), // escaping character &
    hash: 'installationAndFaultFinding',
  },
  {
    icon: 'book',
    title: __('Catalogues and Leaflets'),
    hash: 'cataloguesAndLeaflets',
  },
  {
    icon: 'bell',
    title: __('Product updates'),
    hash: 'productUpdates',
  },
];

// Excluded analytics params list
export const EXCLUDED_PARAMS = [
  'page',
  'fbclid',
];
