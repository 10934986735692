import React, { FC } from 'react';

import { useResize } from '../../hooks';
import __ from '../../../../utils/translation';

import Icon from '../icon';
import DetailHider from './hider';

const DetailDocumentation: FC<{
  list: {
    label: string,
    type: 'asset' | 'url',
    value: string,
  }[],
}> = ({
  list,
}) => {
  if (list?.length === 0) {
    return null;
  }

  const isDesktop = useResize();
  const title = __('Documentation');
  const documents = (
    <div className="catalog-detail__documents">
      <ul className="catalog-detail__documents-list">
        {
          list.map((item, idx) => {
            const iconName = item.type === 'asset'
              ? 'download'
              : 'link';

            return (
              <li className="catalog-detail__documents-item" key={`detail-${idx}`}>
                <div className="catalog-detail__documents-item-inner">
                  <Icon
                    name="book"
                    className="catalog-detail__documents-item-icon catalog-detail__documents-item-icon_static"
                  />
                  <div className="catalog-detail__documents-item-label">
                    <a
                      href={item.value}
                      className="catalog-detail__documents-item-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      { item.label }
                    </a>
                  </div>
                  <div className="catalog-detail__documents-item-type">
                    <div className="catalog-detail__documents-item-download">{__('Download')}</div>
                    <Icon
                      name={iconName}
                      className="catalog-detail__documents-item-icon"
                    />
                  </div>
                </div>
              </li>
            );
          })
        }
      </ul>
    </div>
  );

  if (!isDesktop) {
    return <DetailHider title={title}>{ documents }</DetailHider>;
  }

  return (
        <div className="catalog-detail__block">
            <div className="catalog-detail__block-title">
                <h3 className="heading heading_h3">{ title }</h3>
            </div>
            <div className="catalog-detail__block-content">{ documents }</div>
        </div>
  );
};

export default DetailDocumentation;
