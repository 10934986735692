import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import storeStores from '../../../../store/stores';
import LocationsNearbyListItem from './components/locations-nearby-list-item';
import { Store } from '../../../../store/stores/model';

const LocationsNearby: FC = () => {
  if (!storeStores.storesList.length) return null;

  const selectLocation = (store: Store) => {
    storeStores.setCurrentStore(store);
  };

  const storesList = storeStores.storesList.map(item => {
    return <LocationsNearbyListItem selectLocation={selectLocation} key={item.id} location={item} />;
  });

  return (
    <div className="where-to-buy__sidebar-locations-nearby">
      <div className="where-to-buy__sidebar-filter-label">{storeStores.storesList.length} locations in your area</div>
      <div className="where-to-buy__sidebar-locations-list">{storesList}</div>
    </div>
  );
};

export default observer(LocationsNearby);
