import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Store } from '../../../../../../store/stores/model';
import storeStoresFilters from '../../../../../../store/filters/store-filters';
import {
  getMapsDirectionsLink,
  getShopCustomerType,
} from '../../../../../../helpers/shops';
import __ from '../../../../../../../../utils/translation';

interface ListItemProps {
  location: Store;
  selectLocation: (shop: Store) => void;
}

const TYPE_ICON_MAP = {
  0: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -310)" fill="none" fillRule="evenodd">
        <path d="M24 310h24v24H24z" />
        <path
          fill="#009CDD"
          d="M27 329.72V331a1 1 0 001 1h1a1 1 0 001-1v-1h12v1a1 1 0 001 1h1a1 1 0 001-1v-1.28c.62-.35 1-1 1-1.72v-3a2 2 0 00-1.23-1.84l-1.37-4.1a3 3 0 00-2.84-2.06h-9.12a3 3 0 00-2.84 2.05l-1.37 4.1A2 2 0 0026 325v3a2 2 0 001 1.72zm2.5-1.72a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm13 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-11.06-9h9.12a1 1 0 01.95.68l1.1 3.32H29.4l1.1-3.32a1 1 0 01.95-.68z"
        />
        <path
          fill="#009CDD"
          d="M46 317.39v-2.11l-9.68-3.23a1 1 0 00-.66.01L26 315.57v2.13l10.02-3.64 9.98 3.33z"
        />
      </g>
    </svg>
  ),
  1: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -375)" fill="none" fillRule="evenodd">
        <path d="M24 375h24v24H24z" />
        <path
          fill="#DC0032"
          d="M28.91 378c-.74 0-1.36.47-1.59 1.12l-2.32 6.7v8.94c0 .62.5 1.12 1.12 1.12h1.11c.62 0 1.12-.5 1.12-1.11v-1.12h5.93a7.82 7.82 0 013.48-8.94H27.22l1.68-5.03h12.3l1.31 3.94c.69.07 1.36.24 2 .48l-1.73-4.98a1.68 1.68 0 00-1.58-1.12H28.9m11.74 7.82a.3.3 0 00-.3.24l-.2 1.47c-.34.15-.67.33-.96.53l-1.38-.56c-.13 0-.27 0-.35.14l-1.12 1.94a.28.28 0 00.07.36l1.19.91c-.05.37-.05.75 0 1.12l-1.19.92a.3.3 0 00-.07.36l1.12 1.93c.07.14.21.14.35.14l1.38-.56c.3.2.6.4.95.53l.22 1.48c.02.13.13.23.29.23h2.23c.13 0 .25-.1.27-.23l.21-1.48c.34-.14.64-.32.94-.53l1.38.56c.14 0 .29 0 .37-.14l1.11-1.93a.3.3 0 00-.06-.36l-1.2-.92c.02-.19.05-.37.05-.56 0-.19-.02-.37-.05-.56l1.19-.91a.3.3 0 00.06-.36l-1.12-1.93c-.06-.15-.2-.15-.35-.15l-1.38.56c-.3-.2-.6-.4-.95-.53l-.21-1.47a.26.26 0 00-.26-.24h-2.23m-11.74 1.12a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35m12.86 2.8a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35z"
        />
      </g>
    </svg>
  ),
  2: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -440)" fill="none" fillRule="evenodd">
        <path d="M24 440h24v24H24z" />
        <path
          fill="#3DBD5D"
          fillRule="nonzero"
          d="M44.77 457.63l-7.44-7.44a5.25 5.25 0 00-1.22-5.64 5.4 5.4 0 00-6.05-1.06l3.51 3.51-2.45 2.46-3.6-3.52A5.27 5.27 0 0028.6 452a5.25 5.25 0 005.64 1.23l7.44 7.43c.32.33.81.33 1.14 0l1.88-1.88c.41-.32.41-.9.08-1.14z"
        />
      </g>
    </svg>
  )
};

const LocationsNearbyListItem: FC<ListItemProps> = props => {
  const customerType = getShopCustomerType(props.location);
  const locationType = storeStoresFilters.filtersList.length
    ? storeStoresFilters.filtersList[customerType].label
    : null;

  const handleClick = () => {
    props.selectLocation(props.location);
  };

  const svg = TYPE_ICON_MAP[customerType];

  return (
    <div className="locations-nearby-list-item" onClick={handleClick}>
      <div className="locations-nearby-list-item__title">
        {props.location.name}
      </div>
      <div className="locations-nearby-list-item__type">
        {svg}
        {__(locationType)}
      </div>
      <div className="locations-nearby-list-item__number">
        {props.location.telephone}
      </div>
      <div className="locations-nearby-list-item__actions">
        <a
          target="_blank"
          href={getMapsDirectionsLink(props.location)}
          className="locations-nearby-list-item__actions-item"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g transform="translate(-334 -628)" fillRule="nonzero">
              <path d="M353.63 637.11l-8.74-8.74a1.25 1.25 0 00-1.78 0l-8.74 8.74a1.25 1.25 0 000 1.78l8.74 8.74c.5.5 1.29.5 1.78 0l8.74-8.74c.5-.5.5-1.29 0-1.78zm-3.94.5l-3.29 3.03a.31.31 0 01-.53-.23v-2.1h-3.75v2.5c0 .18-.14.32-.3.32h-1.26a.31.31 0 01-.3-.32v-3.12c0-.7.55-1.25 1.24-1.25h4.37v-2.1c0-.27.33-.41.53-.23l3.29 3.04c.13.12.13.33 0 .46z" />
            </g>
          </svg>
        </a>
        <a
          href={props.location.website}
          className="locations-nearby-list-item__actions-item"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g transform="translate(-390 -628)" fillRule="nonzero">
              <path d="M400 628a10 10 0 100 20 10 10 0 000-20zm3.32 14.42l-.46.45a.97.97 0 00-.25.44c-.06.22-.1.45-.19.68l-.7 1.89c-.56.12-1.13.18-1.72.18v-1.1c.07-.5-.3-1.46-.91-2.07a1.3 1.3 0 01-.38-.9v-1.3c0-.47-.25-.9-.66-1.13-.58-.32-1.4-.77-1.97-1.05-.47-.23-.9-.53-1.28-.88l-.03-.03a4.67 4.67 0 01-.73-.83l-1.4-2.06a8.11 8.11 0 014.17-4.11l.97.48c.42.22.93-.1.93-.58v-.45c.32-.05.65-.09.98-.1l1.14 1.14c.26.25.26.66 0 .91l-.19.2-.41.41a.32.32 0 000 .46l.19.19c.12.12.12.33 0 .45l-.33.32a.33.33 0 01-.22.1h-.37a.32.32 0 00-.22.09l-.4.39c-.1.1-.13.25-.07.37l.63 1.26c.11.22-.05.47-.28.47h-.23a.32.32 0 01-.21-.08l-.38-.33a.64.64 0 00-.62-.12l-1.26.42a.48.48 0 00-.06.89l.44.22c.38.2.8.29 1.22.29.43 0 .91 1.1 1.3 1.29h2.69c.34 0 .67.14.9.38l.56.55c.23.23.36.54.36.87 0 .5-.2.98-.55 1.33zm3.5-3.68a.97.97 0 01-.58-.4l-.72-1.1a.97.97 0 010-1.07l.79-1.18c.1-.14.22-.25.37-.33l.52-.26a8 8 0 01.8 4.63l-1.19-.3z" />
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default observer(LocationsNearbyListItem);
