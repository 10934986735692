// Header
import Header from './header';

// News filters
import ArticlesFilters from './articles-filters';

// News Tabs
import NewsTabs from './news-tabs';

// Parts Search Bar
import SearchBar from './d-search-bar';

// Where to buy app
import WhereToBuy from './where-to-buy';

// Language Switch
import LanguageSwitch from './lang-switch';

// Article Item
import ArticleItem from './article-item';

// Video Popup
import VideoPopup from './video-list';

// Video Popup
import SectionCatcher from './section-catcher';

// More Product Block
import MoreBlock from './more-block';

// Accordion
import Accordion from './accordion';

// Denso Filter
import DensoFilter from './denso-filter';

// Innovations timeline
import Innovations from './innovations';

// Visit Role Drop Down
import VisitRole from './visit-role';

// Back To Top Button
import BackToTop from './back-to-top';

// Find My Part
import FindMyPart from './denso-catalog';

// Team
import Team from './team';

// Contact
import Contact from './contact';

// Locations
// import Locations from './locations';

// Downloads
import Downloads from './downloads';

// Country detect banner
import CountryDetect from './country-detect';

// Sentry
import SentryLog from './sentry';

export default [
  Header,
  NewsTabs,
  SearchBar,
  WhereToBuy,
  LanguageSwitch,
  ArticlesFilters,
  VideoPopup,
  SectionCatcher,
  MoreBlock,
  Innovations,
  Accordion,
  DensoFilter,
  VisitRole,
  BackToTop,
  FindMyPart,
  Team,
  ArticleItem,
  Contact,
  // Locations,
  Downloads,
  SentryLog,
  CountryDetect,
];
