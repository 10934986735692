import { apiRequest } from '../';

const findPart = (q: string) => apiRequest(`/altsearch?q=${q}`, 'GET');

const findGlobal = (q: string) => apiRequest(`/search?q=${q}`, 'GET');

const altSearch = {
  findPart,
  findGlobal,
};

export default altSearch;
