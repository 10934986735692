import { makeAutoObservable, runInAction } from 'mobx';

import { Overall } from './model';

export const overall = makeAutoObservable<Overall>({
  title: '',
  setTitle: (title: string = 'Find My Part') => {
    runInAction(() => {
      overall.title = title;
    });
  },
  baseUrl: '',
  setBaseUrl: (baseUrl: string = '/catalog') => {
    runInAction(() => {
      overall.baseUrl = baseUrl;
    });
  },
  prefix: '',
  setPrefix: prefix => {
    runInAction(() => {
      overall.prefix = prefix ?? 'pv';
    });
  },
  get currentPrefix() {
    if (overall.prefix) {
      return `${overall.prefix}/`;
    }

    return '';
  },
  langId: 4,
  setLangId: (langId: number = 4) => {
    runInAction(() => {
      overall.langId = langId;
    });
  },
  WTBLink: '',
  setWTBLink: (WTBLink: string = '/where-to-buy') => {
    runInAction(() => {
      overall.WTBLink = WTBLink;
    });
  },
});

export default overall;
