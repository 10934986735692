import React, { FC } from 'react';
import { Filter } from '../../../../store/filters/models';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import __ from '../../../../../../utils/translation';

interface FilterTagProps {
  filter: Filter;
  onClose: (isSubfilter: boolean) => void;
  isSubfilter: boolean;
}

const FILTER_ID_TO_COLOR_MAP = {
  1: 'where-to-buy__sidebar-filter-tag_blue',
  2: 'where-to-buy__sidebar-filter-tag_red',
  3: 'where-to-buy__sidebar-filter-tag_green',
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
      <g transform="translate(-205 -275)" fill="#FFF" fillRule="nonzero">
        <path d="M209.94 279l2.864-2.858a.6693.6693 0 000-.946.6693.6693 0 00-.946 0l-2.857 2.864-2.858-2.864a.6693.6693 0 00-.946 0 .6693.6693 0 000 .946l2.864 2.857-2.864 2.858c-.126.125-.197.295-.197.473s.07.348.197.473c.125.126.295.197.473.197s.348-.07.473-.197l2.857-2.864 2.857 2.864c.125.126.296.197.473.197.178 0 .348-.07.473-.197.126-.125.197-.295.197-.473s-.07-.348-.197-.473L209.94 279z" />
      </g>
    </svg>
  );
};

const FilterTag: FC<FilterTagProps> = props => {
  const tagSkin = {
    backgroundColor: props.isSubfilter
      ? 'where-to-buy__sidebar-filter-tag_red'
      : FILTER_ID_TO_COLOR_MAP[props.filter.id],
  };

  const handleClick = () => {
    props.onClose(props.isSubfilter);
  };

  return (
    <div className={cn(
      'where-to-buy__sidebar-filter-tag',
      { [`${tagSkin.backgroundColor}`]: tagSkin.backgroundColor },
    )}>
      {__(props.filter.label)}
      <div onClick={handleClick} className="close">
        <CloseIcon />
      </div>
    </div>
  );
};

export default observer(FilterTag);
