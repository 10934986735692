let downloadsWrapper: HTMLDivElement;
let downloadsFilterForm: HTMLFormElement;
let downloadsVideo: NodeList;

const checkBtnClick = (target) => {
    const availableClasses = ['downloads__filter-header-close', 'downloads__filter-fixed-btn', 'downloads__filter-bg'];

    if (availableClasses.some(item => target.classList.contains(item))) {
        downloadsWrapper.classList.toggle('downloads_open');
    }
};

const youTubeParser = (url: string): string => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const  match = url.match(regExp);

    if (match && match[7].length === 11) {
        return match[7];
    }

    return '';
};

const generateThumbs = (): void => {
    downloadsVideo.forEach((item) => {
        const videoLink = (item as HTMLElement).querySelector('.downloads__card-videolink') as HTMLLinkElement;
        const videoId = youTubeParser(videoLink.href);

        videoLink.dataset.videoId = videoId;

    });
};

const handleChange = (): void => {
    buildSearchStr();
};

const handleClick = (e): void => {
    const target = e.target;
    const isToggleTarget = {...target.dataset}.hasOwnProperty('toggle');

    if (target.dataset.action) {
        const {
            key,
            val,
        } = target.dataset;

        buildSearchStr({
            key,
            val,
        }, target.dataset.action === 'remove');

        return;
    }

    // Clear all visible dropdowns
    const visibleDD = downloadsFilterForm?.querySelectorAll('.downloads__dd_focus');

    if (visibleDD?.length) {
        visibleDD.forEach((item) => {
            if (!(isToggleTarget && target.closest('.downloads__dd').isSameNode(item))) {
                item.classList.remove('downloads__dd_focus');
            }
        });
    }

    if (isToggleTarget) {
        target.closest('.downloads__dd').classList.toggle('downloads__dd_focus');
    }

    checkBtnClick(target);
};

const buildSearchStr = (
    newFilter?: {
        key: string,
        val?: string,
    },
    isRemove?: boolean,
): void => {
    const formData = new FormData(downloadsFilterForm);
    const queryUrl = new URLSearchParams();

    for (const item of formData.entries()) {
        queryUrl.append(item[0], item[1].toString());
    }

    if (newFilter) {
        isRemove
            ? queryUrl.delete(newFilter.key)
            : queryUrl.append(newFilter.key, newFilter.val);

        // Clear subcategory too
        if (newFilter.key === 'cat' && isRemove) {
            queryUrl.delete('group');
        }
    }

    window.location.href = queryUrl.toString() === ''
        ? downloadsFilterForm.action
        : `${downloadsFilterForm.action}?${queryUrl.toString()}`;
};

const events = (): void => {
    downloadsFilterForm?.addEventListener('change', handleChange);
    downloadsWrapper.addEventListener('click', handleClick);
};

const init = (): void => {
  downloadsWrapper = document.querySelector('.downloads');

  if (downloadsWrapper) {
      downloadsFilterForm = downloadsWrapper.querySelector('.downloads__filter-form');
      downloadsVideo = downloadsWrapper.querySelectorAll('.downloads__card_video');

      events();

      if (downloadsVideo.length) {
          generateThumbs();
      }
  }
};

export default {
    init,
};
