import React, { FC, useEffect } from 'react';
import storeUserLocation from '../../store/user-location';
import storeMap from '../../store/map';
import { observer } from 'mobx-react-lite';
import { DEFAULT_ZOOM } from '../../constants';
import { setCustomControls } from '../../helpers/mapControls';
import { findShops } from '../../api';
import storeStores from '../../store/stores';
import { setMarkersRenderer } from '../../helpers/mapMarkers';
import { setMapEvents } from '../../helpers/mapEvents';
import { setFiltersListeners } from '../../helpers/filtersListeners';
import { setDefaultFilter } from '../../helpers/store';
import { DEFAULT_LOCATION } from '../../helpers/location';

const Map: FC = () => {
  if (!('google' in window)) return null;

  const position = storeUserLocation.position;
  const mapOptions = {
    center: {
      lat: position ? position.lat() : DEFAULT_LOCATION.lat,
      lng: position ? position.lng() : DEFAULT_LOCATION.lng
    },
    clickableIcons: false,
    zoom: position ? DEFAULT_ZOOM : 4.5,
    disableDefaultUI: true
  };

  useEffect(() => {
    try {
      const map = new google.maps.Map(
        document.querySelector('.where-to-buy__map') as HTMLElement,
        mapOptions
      );
      setMapEvents(map);
      setCustomControls(map);
      setMarkersRenderer(map);
      storeMap.setInstance(map);
      findShops({}).then(response => {
        storeStores.setStoresList(response, true);
        setFiltersListeners();
        setDefaultFilter();
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return <div className="where-to-buy__map" />;
};

export default observer(Map);
