import { makeAutoObservable, runInAction } from 'mobx';
import { CheckboxFiltersStore, Filter } from '../models';

const storeProductGroupFilters = makeAutoObservable<CheckboxFiltersStore>({
  filtersList: [],
  label: 'Product group',
  alias: 'product_group',
  setList(list) {
    runInAction(() => {
      const convertedList = [];
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          const convertedFilter: Filter = {
            id: parseInt(key),
            label: list[key]
          };
          convertedList.push(convertedFilter);
        }
      }
      storeProductGroupFilters.filtersList = convertedList;
    });
  }
});

export default storeProductGroupFilters;
