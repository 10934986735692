const regionsSelect = document.querySelectorAll('.js-region-select');

function scrollTo(elem) {
    elem = document.querySelector(elem) as HTMLDivElement;
    let headerHeight = document.querySelector('.header__main') as HTMLDivElement;
    let headerHeightMob = document.querySelector('.header') as HTMLDivElement;
    let offset = elem.getBoundingClientRect().top + window.scrollY - headerHeight.offsetHeight;

    if (window.innerWidth < 768) {
        offset = elem.getBoundingClientRect().top + window.scrollY - headerHeightMob.offsetHeight;
    }

    if (offset) {
        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    }
}

function fillRegionsSelect(option) {
    regionsSelect.forEach((item: HTMLFormElement) => {
        item.addEventListener('change', function () {
            scrollTo(this.options[item.selectedIndex].value);
        });
        item.innerHTML += option;
    })
}

const generatePopupContent = (linkElement: HTMLLinkElement): void => {
    const popup = document.getElementById('team-modal') as HTMLElement;
    const popupContent = popup.querySelector('.team-modal__inner');
    const currentItem = linkElement.closest('.team__item') as HTMLImageElement;
    const currentImg = currentItem.querySelector('.team__item-img img') as HTMLImageElement;
    const currentContent = currentItem.querySelector('.team__item-meta');

    if (currentItem && popup) {
        popupContent.innerHTML = `
            <div class="team-modal__img">
                <img src='${currentImg.src}' alt="">
            </div>
            <div class="team-modal__caption">
                ${currentContent.innerHTML}
            </div>
        `;
    }
};

const generateRegionsNav = (): void => {

    const regions = document.querySelectorAll('.team__region');
    const targetNavList = document.querySelector('.team__nav-list') as HTMLDivElement;

    regions.forEach((item) => {
        const link = `<dd class="team__nav-item">
            <div class="arrow-link arrow-link_small">
                <div class="arrow-link__icon">
                    <svg class="arrow-link__icon-svg">
                        <use xlink:href="/dist/images/denso-sprite.svg#right-arrow"></use>
                    </svg>
                </div>
                <a href="#${item.getAttribute('id')}" class="arrow-link__text js-anchor-link">${item.getAttribute('data-region-name')}</a>
            </div></dd>`;

        const option = `<option value="#${item.getAttribute('id')}">${item.getAttribute('data-region-name')}</option>`;

        targetNavList.innerHTML += link;
        fillRegionsSelect(option);
    })
};

const catchESC = (e) => {
    if (e.code === 'Escape') {
        togglePopup(false);
    }
};

const clickOutside = (event): void => {
    if (event.target.classList.contains('team-modal__close') || event.target.classList.contains('team-modal-overlay')) {
        togglePopup(false);
    }
};

const clearPopup = (): void => {
    document.removeEventListener('click', clickOutside);
};

const togglePopup = (isShow: boolean = true): void => {
    document.documentElement.classList.toggle('show-team-popup', isShow);

    isShow
        ? document.addEventListener('click', clickOutside, false)
        : clearPopup();
};

const catchLink = (e: Event): void => {
    const target = e.target as HTMLLinkElement;

    if (target.classList.contains('js-team-popup-toggle')) {
        e.preventDefault();

        if (!document.documentElement.classList.contains('show-team-popup')) {
            generatePopupContent(target);
        }
        togglePopup();
    }

    if (target.href && target.href.indexOf("#") != -1) {
        e.preventDefault();
        scrollTo(target.getAttribute('href'));
    }

};

const events = (): void => {
    document.addEventListener('click', catchLink);
    document.addEventListener('keyup', catchESC);
};

const init = (): void => {
    if (regionsSelect.length) {
        events();
        generateRegionsNav();
    }
};

export default {
    init,
};
