import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import __ from '../../../../utils/translation';

import specificationsListByLang from '../../utils/specificationsListByLang';
import Specs from '../specs';
import Icon from '../icon';

const RecentlyCard: FC<{
  item: {
    name: string;
    tid: number;
    title: any[];
    cover: string;
    criteria: any[];
  };
}> = ({ item }) => {
  const { name, title, cover, criteria } = item;
  const specs = specificationsListByLang(
    {
      criteria: criteria.length > 5 ? criteria.slice(0, 5) : criteria,
    },
  );

  return (
    <div className="catalog-detail__recently-card">
      <div className="catalog-detail__recently-card-head">
        <div className="catalog-detail__recently-card-media">
          {cover ? (
            <img
              className="catalog-detail__recently-card-img"
              src={cover}
              alt={name}
              loading="lazy"
              decoding="async"
            />
          ) : (
            <Icon
              name="empty-image"
              className="catalog-detail__recently-card-icon"
            />
          )}
        </div>
        <div className="catalog-detail__recently-card-main">
          <div className="catalog-detail__recently-card-name">
            { title }
          </div>
          <div className="heading heading_h3">
            { `${ __('PowerEdge part number') }: ${ name }` }
          </div>
        </div>
      </div>

      <div className="catalog-detail__recently-card-content">
        {specs.length > 0 ? <Specs list={specs} isSmall /> : null}
      </div>

      <div className="catalog-detail__recently-card-foot">
        <Link
          to={`/part/${name}`}
          className="button button_outline button_small"
        >
          { __('More information') }
        </Link>
      </div>
    </div>
  );
};

const DetailRecentlyViewed: FC<{
  currentId: number;
}> = ({ currentId }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const recentlyItems = localStorage.getItem('denso-rv');
    const itemsArray = recentlyItems ? JSON.parse(recentlyItems) : [];

    if (Array.isArray(itemsArray)) {
      setList(itemsArray.filter(item => item.tid !== currentId));
    }
  }, []);

  if (list.length === 0) {
    return null;
  }

  return (
    <div className="catalog-detail__block catalog-detail__block_rview">
      <div className="catalog-detail__block-title">
        <h3 className="heading heading_h2">{ __('Recently viewed by you') }</h3>
      </div>
      <div className="catalog-detail__block-content">
        <div className="catalog-detail__recently">
          <div className="catalog-detail__recently-grid">
            {list.slice(-2).map(item => (
              <div key={item.tid} className="catalog-detail__recently-item">
                <RecentlyCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRecentlyViewed;
