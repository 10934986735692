declare const window: any;

const PASS = window.DENSO?.apiUsername;
const USERNAME = window.DENSO?.apiPassword;

export interface ShopFilters {
  product_group?: number[];
  type_of_sales?: number[];
  type_of_store?: number[];
  type_of_location?: number[];
  city?: string;
  subtype_of_store?: string[];
}

interface FilterMap {
  [key: number]: string[];
}

interface FiltersResponse {
  type_of_sales: FilterMap;
  type_of_store: FilterMap;
  type_of_location: FilterMap;
  product_group: FilterMap;
}

const BASE_URL = '/where-to-buy/api/v1'

const headers = new Headers();
headers.set('content-type', 'application/json');
headers.set('Authorization', 'Basic ' + window.btoa(USERNAME + ':' + PASS));

export async function getFilters(): Promise<FiltersResponse> {
  try {
    const response = await fetch(`${BASE_URL}/filters`, {
      method: 'get',
      headers
    });
    return await defaultResponseHandler(response);
  } catch (e) {
    console.error(e);
  }
}

export async function findShops(filters: ShopFilters) {
  try {
    const response = await fetch(`${BASE_URL}/shops`, {
      method: 'post',
      headers,
      body: JSON.stringify(filters)
    });
    return await defaultResponseHandler(response);
  } catch (e) {
    console.error(e);
  }
}

export async function getFilterSubtype(filterId: number): Promise<string[]> {
  try {
    const response = await fetch(`${BASE_URL}/customer/${filterId}/types`, {
      method: 'get',
      headers
    });
    return await defaultResponseHandler(response);
  } catch (e) {
    console.error(e);
  }
}

function throwError(message) {
  throw new Error(message);
}

async function defaultResponseHandler(response) {
  const json = await response.json();
  if (json.status !== 'success') throwError('Request failed');
  return 'data' in json ? json.data : [];
}
