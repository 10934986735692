import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Filter } from '../../../../store/filters/models';
import MainFilterIcon from './components/main-filter-icon';
import MainFilterCta from './components/main-filter-cta';
import __ from '../../../../../../utils/translation';

interface MainFiltersItemProps {
  filter: Filter | string;
  handleClick: (filter: Filter | string) => void;
  isSubfilter: boolean;
}

const MainFiltersItem: FC<MainFiltersItemProps> = props => {
  if (!props.filter) return null;

  const handleClick = () => {
    props.handleClick(props.filter);
  };

  const label =
    typeof props.filter === 'string' ? props.filter : props.filter.label;
  const id = typeof props.filter === 'string' ? props.filter : props.filter.id;

  return (
    <div
      onClick={handleClick}
      className="where-to-buy__sidebar-main-filters-item"
    >
      <div className="column">
        <MainFilterIcon filterId={id} isSubfilter={props.isSubfilter} />
        {__(label)}
      </div>
      <div className="column">
        <MainFilterCta />
      </div>
    </div>
  );
};

export default observer(MainFiltersItem);
