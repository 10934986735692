// @ts-nocheck
let videoList: HTMLElement;
let popup: HTMLElement;
let popupContent: HTMLElement;

const youTubeParser = (url: string): string => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const  match = url.match(regExp);

    if (match && match[7].length === 11) {
        return match[7];
    }

    return '';
};

const generateIframeUrl = (videoId: string): string => {
    return `https://www.youtube.com/embed/${ videoId }?rel=0&showinfo=0&autoplay=1`;
};

const generateIframe = (linkElement: HTMLLinkElement): void => {
    const videoId = linkElement.dataset.videoId;
    let iframe = document.createElement('iframe');

    if (videoId) {
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('src', generateIframeUrl(videoId));
        iframe.classList.add('video-list-popup__content-iframe');

        popupContent.append(iframe);
    }
};

const clearPopup = (): void => {
    document.removeEventListener('click', clickOutside);

    popupContent.innerHTML = '';
};

const clickOutside = (e: Event): void => {
    const target = e.target as HTMLElement;

    if (popupContent.contains(target)) {
        e.preventDefault();
        return;
    }

    togglePopup(false);
};

const togglePopup = (isShow: boolean = true): void => {
    document.documentElement.classList.toggle('show-video-popup', isShow);

    isShow
        ? document.addEventListener('click', clickOutside, false)
        : clearPopup();
};

const catchVideoLink = (e: Event): void => {
    const target = e.target as HTMLLinkElement;

    if (target.classList.contains('video-list__item-link')) {
        e.preventDefault();

        generateIframe(target);
        togglePopup();
    }
};

const events = (): void => {
    document.addEventListener('click', catchVideoLink, false);
};

const createPopup = (): void => {
    popup = document.createElement('div');
    popupContent = document.createElement('div');

    popup.classList.add('video-list-popup');
    popupContent.classList.add('video-list-popup__content-video');

    popup.innerHTML = `
        <div class="video-list-popup__content"></div>
        <button class="video-list-popup__close" aria-label="Close popup">
            <span class="video-list-popup__close-icon" aria-hidden="true">Close</span>
        </button>
    `;
    popup.querySelector('.video-list-popup__content').append(popupContent);

    document.body.append(popup);
};

const setInitialImages = (): void => {
    const videoItems = document.querySelectorAll('.video-list__item');

    if (videoItems.length) {
        videoItems.forEach((videoItem) => {
            const videoLink: HTMLLinkElement = videoItem.querySelector('.video-list__item-link');
            const videoMedia = videoItem.querySelector('.video-list__item-media');
            const videoId = youTubeParser(videoLink.href);
            const thumbnailUrl = 'https://img.youtube.com/vi';

            videoLink.dataset.videoId = videoId;

            videoMedia.insertAdjacentHTML('afterbegin', `
                <picture class="video-list__item-picture">
                    <source
                        srcset="${thumbnailUrl}/${ videoId }/0.jpg"
                        type="image/webp"
                    >
                    <img
                        src=${thumbnailUrl}/${ videoId }/maxresdefault.jpg"
                        alt="${ videoLink.textContent }"
                        class="video-list__item-img"
                        loading="lazy"
                        decoding="async"
                    >
                </picture>
            `);
        });
    }
};

const init = (): void => {
    videoList = document.querySelector('.video-list');

    if (videoList) {
        setInitialImages();
        createPopup();
        events();
    }
};

export default {
    init,
};
