let accordionList: HTMLElement;

const toggleBlock = (toggleBtn: HTMLButtonElement) => {
    const parentItem = toggleBtn.closest('.accordion__item');

    if (parentItem) {
        parentItem.classList.toggle('accordion__item_open');
    }
};

const catchButton = (e: Event): void => {
    const target = e.target as HTMLButtonElement;

    if (target.classList.contains('accordion__item-btn')) {
        e.preventDefault();

        toggleBlock(target);
    }
};

const events = (): void => {
    document.addEventListener('click', catchButton);
};

const init = (): void => {
    accordionList = document.querySelector('.accordion');

    if (accordionList) {
        events();
    }
};

export default  {
    init,
};
