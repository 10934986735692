import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import cn from 'classnames';

import Modal from '../modal';
import { useResize } from '../../hooks';
import Icon from '../icon';

const DetailSlider: FC<{
  images?: {
    thumb: string,
    origin: string,
    isSpin?: boolean,
  }[],
  spinImage?: string,
  docs?: {
    origin: string,
    thumb: string,
  }[],
  isWiperBlades?: boolean,
}> = ({
  images,
  spinImage,
  docs,
  isWiperBlades = false,
}) => {
  const isDesktop = useResize();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSpinImg, setIsSpinImg] = useState(false);
  const [isZoom, setIsZoom] = useState(false);
  const isHidden = (!images || images.length === 0) && !spinImage;
  let normalizedImages = useMemo(() => {
    const returnImages =  images?.map((image, index) => ({
      id: !spinImage ? index : index + 1,
      thumb: image.thumb,
      origin: image.origin,
      isSpin: false,
    })) ?? [];

    if (spinImage) {
      return [
        {
          id: 0,
          thumb: spinImage.replace(/\.[^/.]+$/, '.jpg'),
          origin: spinImage,
          isSpin: true,
        },
        ...returnImages,
      ];
    }

    return returnImages;
  }, []);
  const docsDownloadLink = docs?.length > 0
    ? useMemo(() => {
      return (
        <div className="catalog-detail__pdf">
          <div className="catalog-detail__pdf-icon">
            <Icon name="book" />
          </div>

          <a
            href={ docs[0].origin }
            target="_blank"
            rel="noreferrer noopener"
            className="catalog-detail__pdf-link"
          >Download {isWiperBlades ? 'instructions' : 'PDF'}</a>

          <div className="catalog-detail__pdf-icon catalog-detail__pdf-icon_arr">
            <Icon name="right-arrow" />
          </div>
        </div>
      );
    }, [])
    : null;

  const handleClose = useCallback(() => {
    setIsSpinImg(false);
    setIsZoom(false);
  }, []);

  const handleZoom = useCallback((e) => {
    e.stopPropagation();

    setIsZoom(true);
  }, []);

  const handleMouseEnter = useCallback((nextIndex) => () => {
    setActiveIndex(nextIndex);
  }, []);

  const handleSpinClick = () => {
    setIsSpinImg(true);
    setIsZoom(true);
  };

  // Arrow catch for prev/next images
  const catchArrow = (e) => {
    const code = e.code as string;
    const availableCodes = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];

    if (normalizedImages?.length > 1 && (availableCodes.includes(code))) {
      let nextIndex = e.code === 'ArrowLeft' || e.code === 'ArrowUp'
        ? activeIndex - 1
        : activeIndex + 1;

      nextIndex = nextIndex >= normalizedImages.length
        ? 0
        : (
          nextIndex < 0
            ? normalizedImages.length - 1
            : nextIndex
        );

      setActiveIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (isZoom && !isSpinImg) {
      window.addEventListener('keydown', catchArrow);
    }

    return () => {
      window.removeEventListener('keydown', catchArrow);
    };
  }, [activeIndex, isZoom]);

  const handleMobileClick = (isPrev = false) => () => {
    catchArrow({
      code: isPrev ? 'ArrowLeft' : 'ArrowRight',
    });
  };

  if (isHidden) {
    return <>
      <div className="catalog-detail__overall-empty">
        <Icon
          name="empty-image"
          className="catalog-detail__overall-empty-icon"
        />
      </div>

      { docsDownloadLink }
    </>;
  }

  return (
    <>
      <div className="catalog-detail__slider">
        {
          isDesktop
            ? <div className="catalog-detail__slider-thumb">
              <div className="catalog-detail__slider-thumb-stick">
                {
                  normalizedImages.map(({
                    id,
                    thumb,
                    isSpin,
                  }) => {
                    return (
                      <div
                        key={`thumb-image-${id}`}
                        className={cn(
                          'catalog-detail__slider-thumb-item',
                          { 'catalog-detail__slider-thumb-item_active': activeIndex === id },
                          { 'catalog-detail__slider-thumb-item_360': isSpin },
                        )}
                        onMouseEnter={handleMouseEnter(id)}
                        onFocus={handleMouseEnter(id)}
                        onClick={activeIndex === id ? handleZoom : null}
                        tabIndex={0}
                      >
                        {
                          isSpin
                            ? 360
                            : <img
                              className="catalog-detail__slider-thumb-img"
                              src={thumb}
                              alt="Product image"
                              loading="lazy"
                              decoding="async"
                            />
                        }
                      </div>
                    );
                  })
                }
              </div>
            </div>
            : null
        }

        <div className="catalog-detail__slider-wrap">
          <div className="catalog-detail__slider-main catalog-detail__slider-main_zoom">
            <img
              className="catalog-detail__slider-main-img"
              src={normalizedImages[activeIndex].thumb}
              alt="Middle size product image"
              loading="lazy"
              decoding="async"
              onClick={handleZoom}
            />

            {
              // Spin Button
              normalizedImages[activeIndex].isSpin
                ? <button
                      className="catalog-detail__slider-spin"
                      type="button"
                      onClick={handleSpinClick}
                  >360°</button>
                : null
            }
          </div>

          {
            // Mobile Slider Buttons
            !isDesktop && images.length > 1
              ? <>
                <button
                  className={cn(
                    'catalog-detail__slider-btn catalog-detail__slider-btn_prev',
                    {'catalog-detail__slider-btn_active': activeIndex !== 0},
                  )}
                  type="button"
                  onClick={handleMobileClick(true)}
                >
                  <Icon
                    className="catalog-detail__slider-btn-svg"
                    name="right-arrow"
                  />
                </button>
                <button
                  className={cn(
                    'catalog-detail__slider-btn',
                    {'catalog-detail__slider-btn_active': activeIndex < images.length - 1},
                  )}
                  type="button"
                  onClick={handleMobileClick()}
                >
                  <Icon
                    className="catalog-detail__slider-btn-svg"
                    name="right-arrow"
                  />
                </button>
              </>
              : null
          }

          { docsDownloadLink }
        </div>

        {
          !isDesktop && images.length > 1
            ? <div className="catalog-detail__slider-curr">Image { activeIndex + 1 } of { images.length }</div>
            : null
        }
      </div>

      {
        isZoom
          ? <Modal
              onClose={handleClose}
              isImage
            >
              {
                normalizedImages[activeIndex].isSpin
                  ? <iframe
                      className="catalog-detail__slider-iframe"
                      src={spinImage}
                      width="480"
                      height="480"
                      frameBorder="0"
                    />
                  : <img
                      className="catalog-detail__slider-zoom"
                      src={normalizedImages[activeIndex].origin}
                      alt="Slider zoom image"
                      loading="lazy"
                      decoding="async"
                      onClick={handleClose}
                    />
              }
            </Modal>
          : null
      }
    </>
  );
};

export default DetailSlider;
