import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../api';
import overall from '../../store/overall';
import parts from '../../store/parts';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import breadcrumbs from '../../store/breadcrumbs';
import filter from '../../store/filter';

import { useResize } from '../../hooks';
import { VehicleType } from '../../utils/vehicleType';

import DetailBack from './back';
import DetailOverall from './overall';
import DetailInformation from './information';
import DetailApplication from './application';
import DetailSpecifications from './specifications';
import DetailDocumentation from './documentation';
import DetailOE from './oe-numbers';
import DetailRecentlyViewed from './recently-viewed';
import DetailApplicableHider from './aplicability-hider';
import DetailNavigation from './navigation';


const Detail: FC = () => {
  const isDesktop = useResize();
  const { partName, vType } = useParams<{ partName: string, vType: VehicleType }>();
  const title = parts.currentPart
    ? `PowerEdge ${parts.currentPart.title}`
    : null;

  const backLink = () => {
    const categoryBreadcrumbs = breadcrumbs.list.filter(breadcrumbItem => !breadcrumbItem.isPart);
    if (categoryBreadcrumbs.length > 1) {
      return `/${overall.prefix}${Array.from(categoryBreadcrumbs).pop().link}`;
    }
    return `/${overall.prefix}`;
  };

  const checkRecentlyViewed = recentlyItem => {
    // Get Recently Viewed Items from Local Storage
    const recentlyItems = localStorage.getItem('denso-rv');
    let resultItems = [];

    if (recentlyItems) {
      resultItems = JSON.parse(recentlyItems);
      resultItems = resultItems?.length > 0 ? resultItems : [];

      resultItems = resultItems
        .filter(item => item.tid !== recentlyItem.tid)
        .slice(0, 2);
    }

    resultItems.unshift({
      name: recentlyItem.name,
      tid: recentlyItem.tid,
      title: recentlyItem.title,
      cover:
        recentlyItem.images?.length > 0 ? recentlyItem.images[0].thumb : null,
      criteria: recentlyItem.criteria,
    });

    localStorage.setItem('denso-rv', JSON.stringify(resultItems));
  };

  useEffect(() => {
    const currentLevels = filter.levels?.length > 0
      ? filter.levels.map(level => level.slug)
      : null;

    window.scrollTo(0, 0);

    api.parts
      .getDetail(partName, storeCurrentVehicle.current?.id, vType, currentLevels)
      .then(res => res.json())
      .then(res => {
        if (res && res.data.length) {
          parts.setCurrentPart(res.data[0]);
          const newLinkName = `PowerEdge ${__('part number')}: ${res.data[0].name}`;
          const currentLinkName = breadcrumbs.list[breadcrumbs.list.length - 1]?.title;
          const { levels } = res.data[0];

          if (newLinkName !== currentLinkName) {
            const links = [
              {
                title: __('E-Catalogue'),
                link: `/${overall.prefix}`,
                isPart: false,
              },
              ...levels.reduce((prev, curr, idx) => {
                return [
                  ...prev,
                  {
                    title: curr.title,
                    link: idx === 0 ? `/${curr.slug}` : `${prev[idx - 1].link}/${curr.slug}`,
                    isPart: false,
                  },
                ];
              }, []),
              {
                title: newLinkName,
                link: '/',
                isPart: true,
              },
            ];
            breadcrumbs.setList(links);
          }

          if (res.data[0]) {
            checkRecentlyViewed(res.data[0]);
          }
        }
      })
      .catch(error => console.error(error));

    return () => {
      parts.setCurrentPart(null);
    };
  }, [partName, storeCurrentVehicle.currentId]);

  return (
    <div className="catalog-detail">
      <div className="wrapper">
        {title ? <DetailBack backLink={ backLink() } /> : null}
      </div>

      {parts.currentPart ? (
        <>
          <div className="wrapper">
            <DetailOverall />
          </div>
          <DetailNavigation />

          <div className="wrapper">
            <div className="grid">
              <div className="grid__col grid__col_12 grid__col_lg_9">
                <DetailApplication />
                <DetailSpecifications />

                <DetailDocumentation list={parts.currentPart.downloads} />

                <DetailOE />

                {
                  !isDesktop
                    ? <DetailApplicableHider />
                    : null
                }
                <DetailInformation
                    markup={parts.currentPart.product_information}
                />
              </div>

              {
                isDesktop
                  ? <div className="grid__col grid__col_3">
                      <div className="catalog-detail__side-banner">
                        {parts.currentPart.images?.length ? (
                            <div className="catalog-detail__side-banner-cover">
                              <img
                                  className="catalog-detail__side-banner-img"
                                  src={parts.currentPart.images[0].thumb}
                                  alt="Thumb image"
                                  loading="lazy"
                                  decoding="async"
                              />
                            </div>
                        ) : null}
                        <div className="catalog-detail__side-banner-content">
                          <div className="heading heading_h4">{title}</div>
                        </div>
                      </div>
                    </div>
                  : null
              }
            </div>

            <DetailRecentlyViewed currentId={parts.currentPart.tid} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default observer(Detail);
