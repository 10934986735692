import '../styles/main.scss';

import Components from '../components';

if (Components.length) {
  Components.forEach((component) => component.init());
}

// if (module.hot) {
//   module.hot.accept('components', () => {
//     if (Components.length) {
//       Components.forEach((component) => component.init());
//     }
//   });
// }
