import storeLocationFilters from '../store/filters/location-filters';
import storeProductGroupFilters from '../store/filters/product-group-filters';
import storeSalesFilters from '../store/filters/sales-filters';
import storeStoresFilters from '../store/filters/store-filters';
import storeCurrentFilters from '../store/filters';

export function setStoreFilters(filters) {
  storeLocationFilters.setList(filters.type_of_locations);
  storeProductGroupFilters.setList(filters.product_group);
  storeSalesFilters.setList(filters.type_of_sales);
  storeStoresFilters.setList(filters.type_of_store);
}

export function setDefaultFilter() {
  // TODO Rewrite default filter logic with enums
  const urlParams = new URLSearchParams(window.location.search);
  const defaultFilterParam = urlParams.get('filter');
  const availableFilters = ['location', 'services'];
  const currentFilter = availableFilters.indexOf(defaultFilterParam);

  if (currentFilter !== -1) {
    const locationToBuyFilter = storeStoresFilters.filtersList[currentFilter];

    storeCurrentFilters.setCurrentFilters(
      [locationToBuyFilter],
      storeStoresFilters.alias
    );
  }
}
