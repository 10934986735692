import { makeAutoObservable, runInAction } from 'mobx';
import LocationsStore from './model';

const storeStores = makeAutoObservable<LocationsStore>({
  currentStore: null,
  storesList: [],
  globalStoresList: [],
  setCurrentStore(location) {
    runInAction(() => {
      storeStores.currentStore = location;
    });
  },
  setStoresList(locations, global = false) {
    runInAction(() => {
      if (global) {
        storeStores.globalStoresList = locations;
        return;
      }
      storeStores.storesList = locations;
    });
  }
});

export default storeStores;
