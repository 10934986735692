import { DEFAULT_ZOOM } from '../constants';
import storeMap from '../store/map';
import { findShops } from '../api';
import storeStores from '../store/stores';

function parseDefaultAddress(place: google.maps.GeocoderResult): string {
  let address = '';
  for (const component of place.address_components) {
    if (component.types.includes('locality')) address += component.long_name;
    if (component.types.includes('country'))
      address += ', ' + component.long_name;
  }
  return address;
}

export function getCityFromGeocoderResults(
  place: google.maps.GeocoderResult
): string | null {
  let city = null;
  for (const component of place.address_components) {
    if (component.types.includes('locality')) city = component.long_name;
  }

  return city;
}

function setAddressFieldInitialValue(
  input: HTMLInputElement,
  position: google.maps.LatLng
) {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode(
    { location: position },
    (
      results: google.maps.GeocoderResult[],
      status: google.maps.GeocoderStatus
    ) => {
      if (status === 'OK') {
        if (results[0]) {
          const place = results[0];
          const city = getCityFromGeocoderResults(place);
          storeMap.setCurrentCity(city);
          if ('address_components' in place) {
            input.value = parseDefaultAddress(place);
          }
          findShops({
            city
          }).then(shops => {
            storeStores.setStoresList(shops, false);
          });
        }
      }
    }
  );
}

export function initAutocomplete(
  position: google.maps.LatLng,
  inputId: string
): google.maps.places.Autocomplete {
  const input = document.getElementById(inputId) as HTMLInputElement;

  const options = {
    fields: ['formatted_address', 'geometry'],
    strictBounds: false,
    types: ['geocode', 'establishment']
  } as google.maps.places.AutocompleteOptions;
  let autocomplete = new google.maps.places.Autocomplete(input, options);

  setAddressFieldInitialValue(input, position);

  return autocomplete;
}

export function handlePlaceChange(
  map: google.maps.Map,
  autocomplete: google.maps.places.Autocomplete
) {
  const place = autocomplete.getPlace();
  if (!place.geometry || !place.geometry.location) {
    window.alert("No details available for input: '" + place.name + "'");
    return;
  }

  if (place.geometry.viewport) {
    map.fitBounds(place.geometry.viewport);
  } else {
    map.setCenter(place.geometry.location);
  }
  map.setZoom(DEFAULT_ZOOM);
}
