let moreBlock: HTMLElement;

const toggleBlock = (toggleBtn: HTMLButtonElement) => {
    const parentItem = toggleBtn.closest('.more-block');

    if (parentItem) {
        parentItem.classList.toggle('more-block_open');
    }
};

const catchButton = (e: Event): void => {
    const target = e.target as HTMLButtonElement;

    if (target.classList.contains('more-block__toggle')) {
        e.preventDefault();

        toggleBlock(target);
    }
};

const events = (): void => {
    document.addEventListener('click', catchButton);
};

const init = (): void => {
    moreBlock = document.querySelector('.more-block');

    if (moreBlock) {
        events();
    }
};

export default  {
    init,
};
