const init = (): void => {
  window.addEventListener('load', () => {
    const articleFilters = document.querySelector('.articles-filters')
    if(articleFilters) {
      articleFilters.classList.remove('preload');
    }
  })
  const form: HTMLFormElement = <HTMLFormElement>document.getElementById('articles-filters-form');
  if (!form) {
    return;
  }
  const customSubmit = document.getElementById('articles-filters-submit') as HTMLDivElement;
  const filterCloseButton = document.querySelector('.articles-filters__trigger-close') as HTMLDivElement;
  const filterTriggerCheckbox = document.querySelector('.articles-filters__trigger-input') as HTMLInputElement;

  customSubmit.addEventListener('click', event => {
    event.preventDefault();
    event.stopPropagation();
    handleFormSubmit(form);
  });

  filterCloseButton.addEventListener('click', () => {
    filterTriggerCheckbox['checked'] = false;
  });

  // Auto-open filter on desktop
  if (
    filterTriggerCheckbox.classList.contains('articles-filters__trigger-input-is-opened') &&
    window.innerWidth >= 1024
  ) {
    filterTriggerCheckbox['checked'] = true;
  }
};

function handleFormSubmit(form: HTMLFormElement): void {
  form.submit();
}

export default {
  init,
};
