import { getCityFromGeocoderResults } from './addressField';
import storeMap from '../store/map';

export function setMapEvents(map: google.maps.Map): void {
  map.addListener(
    'center_changed',
    debounce(() => {
      if (!storeMap.updateOnMove) return;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location: map.getCenter() },
        (
          results: google.maps.GeocoderResult[],
          status: google.maps.GeocoderStatus
        ) => {
          if (status === 'OK') {
            storeMap.setCurrentCity(getCityFromGeocoderResults(results[0]));
          }
        }
      );
    }, 1400)
  );
}

function debounce(f, ms) {
  let isCooldown = false;

  return function () {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}
