import { reaction } from 'mobx';
import storeCurrentFilters from '../store/filters';
import storeMap from '../store/map';
import { findShops } from '../api';
import storeStores from '../store/stores';

function defaultReactionHandler(filter, filterLabel) {
  const changedFilters = !filter.length
    ? undefined
    : filter.map(entry => {
        return entry.id;
      });
  findShops(
    Object.assign(
      { [filterLabel]: changedFilters },
      storeCurrentFilters.getFormattedFilters()
    )
  ).then(shops => {
    storeStores.setStoresList(shops, true);
  });
}

export function setFiltersListeners() {
  reaction(
    () => storeCurrentFilters.currentFilters.type_of_sales.length,
    () => {
      const filter = storeCurrentFilters.currentFilters.type_of_sales;
      defaultReactionHandler(filter, 'type_of_sales');
    }
  );
  reaction(
    () => storeCurrentFilters.currentFilters.product_group.length,
    () => {
      const filter = storeCurrentFilters.currentFilters.product_group;
      defaultReactionHandler(filter, 'product_group');
    }
  );
  reaction(
    () => storeCurrentFilters.currentFilters.type_of_store,
    filter => {
      defaultReactionHandler(filter, 'type_of_store');
    }
  );
  reaction(
    () => storeCurrentFilters.currentFilters.subtype_of_store,
    filter => {
      defaultReactionHandler(filter, 'subtype_of_store');
    }
  );
  reaction(
    () => storeCurrentFilters.currentFilters.type_of_location.length,
    () => {
      const filter = storeCurrentFilters.currentFilters.type_of_location;
      defaultReactionHandler(filter, 'type_of_location');
    }
  );
  reaction(
    () => storeMap.currentCity,
    city => {
      if (!city) return;
      findShops({
        city
      }).then(shops => {
        storeStores.setStoresList(shops, false);
      });
    }
  );
}
