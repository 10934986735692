import React from 'react';
import ReactDOM from 'react-dom';

import overall from './store/overall';
import filter from './store/filter';
import storeCurrentVehicle from '../d-search-bar/store/current-vehicle';
import storeAlternativeSearch from '../d-search-bar/store/alternative';
import storeVehicleBrands from '../d-search-bar/store/vehicle-brands';

import titleByLang from './utils/titleByLang';
import { getVTypeName } from './utils/vehicleType';

import App from './app';
import { setOverallListeners } from './store/overall/listeners';
import { setCarLists } from '../d-search-bar/utils/set-car-lists';

let entryPoint: HTMLElement;

const initApp = (): void => {
  localStorage.removeItem('denso-rv');

  // Settings before render component

  // Overall
  setOverallListeners();
  // Overall - Base URL (Craft page slug)
  overall.setBaseUrl(entryPoint.dataset.link);

  // Overall - Where to buy page link
  overall.setWTBLink(entryPoint.dataset?.wtb);

  // Overall - set title
  overall.setTitle(entryPoint.dataset.title);

  // Initial Make
  if (entryPoint.dataset.initialMake) {
    storeVehicleBrands.setInitialMake(entryPoint.dataset.initialMake);
  }

  // Current Car
  if (entryPoint.dataset.car) {
    const initialCar = JSON.parse(entryPoint.dataset.car);
    const vehicleType = getVTypeName(initialCar.vehicle_type);
    const id = initialCar.tid;
    const model = titleByLang(initialCar.model_group, overall.langId);
    const type = initialCar.filters.types.find(el => el.key === id).val;
    const car = {
      id,
      brand: titleByLang(initialCar.maker, overall.langId),
      model,
      year: initialCar.start_year
        ? `${initialCar.start_year}${initialCar.end_year ? ` — ${initialCar.end_year}` : '+'}`
        : 0,
      body: titleByLang(initialCar.body, overall.langId),
      volume: `${initialCar.volume} ${titleByLang(
        initialCar.fuel,
        overall.langId,
      )}`,
      type,
      vehicleType,
    };

    storeCurrentVehicle.setCurrent(car);
    overall.setPrefix(`${vehicleType}/${id}`);
    setCarLists(JSON.parse(entryPoint.dataset.car), car);
  }

  // Referral Search String
  if (entryPoint.dataset.referralSearch) {
    storeAlternativeSearch.setCurrent(entryPoint.dataset.referralSearch);
  }

  // Active categories array
  if (entryPoint.dataset.levels) {
    filter.setInitialLevels(JSON.parse(entryPoint.dataset.levels));
  }

  ReactDOM.render(React.createElement(App), entryPoint);
};

const init = (): void => {
  entryPoint = document.querySelector('#denso-catalog');

  if (entryPoint) {
    initApp();
  }
};

export default {
  init,
};
