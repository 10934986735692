import { makeAutoObservable, runInAction } from 'mobx';
import { CheckboxFiltersStore, Filter } from '../models';

const storeSalesFilters = makeAutoObservable<CheckboxFiltersStore>({
  filtersList: [],
  label: 'Type of sales',
  alias: 'type_of_sales',
  setList(list) {
    runInAction(() => {
      const convertedList = [];
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          const convertedFilter: Filter = {
            id: parseInt(key),
            label: list[key]
          };
          convertedList.push(convertedFilter);
        }
      }
      storeSalesFilters.filtersList = convertedList;
    });
  }
});

export default storeSalesFilters;
