import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import parts from '../../store/parts';
import { useResize } from '../../hooks';

import Specs from '../specs';
import DetailHider from './hider';

const DetailSpecifications: FC = () => {
  const isDesktop = useResize();
  const title = __('Application specifications');

  if (!parts.currentPart?.notes.length) {
    return null;
  }

  if (!isDesktop) {
    return (
      <DetailHider title={title}>
        {
          parts.currentPart.notes.map((specBlock, index) => (
            <Specs
              key={`application-specs-${index}`}
              list={specBlock}
              isBorder
              isCluster
            />
          ))
        }
      </DetailHider>
    );
  }

  return (
    <div className="catalog-detail__block" id="part-application">
      <div className="catalog-detail__block-title">
        <h3 className="heading heading_h3">{ title }</h3>
      </div>

      <div className="catalog-detail__block-content">
        {
          parts.currentPart.notes.map((specBlock, index) => (
            <Specs
              key={`application-specs-${index}`}
              list={specBlock}
              isBorder
              isCluster
            />
          ))
        }
      </div>
    </div>
  );
};

export default observer(DetailSpecifications);
