import { makeAutoObservable, runInAction } from 'mobx';
import { Filter, MainFiltersStore } from '../models';

const storeStoresFilters = makeAutoObservable<MainFiltersStore>({
  filtersList: [],
  subfiltersList: [],
  alias: 'type_of_store',
  subfilterAlias: 'subtype_of_store',
  setList(list, isSubfilter) {
    runInAction(() => {
      const convertedList = [];
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          const convertedFilter: Filter = {
            id: parseInt(key),
            label: list[key]
          };
          convertedList.push(convertedFilter);
        }
      }
      if (isSubfilter) {
        storeStoresFilters.subfiltersList = convertedList;
      } else {
        storeStoresFilters.filtersList = convertedList;
      }
    });
  }
});

export default storeStoresFilters;
