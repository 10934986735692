import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import overviewPartInfo from '../../utils/overviewPartInfo';
import specificationsListByLang from '../../utils/specificationsListByLang';
import __ from '../../../../utils/translation';

import Specs from '../specs';
import Icon from '../icon';
import PartsItemReplaces from './itemReplaces';

export interface Replaces {
  name: string;
  url: string;
}

const PartsItem: FC<{
  prefix?: string,
  item: {
    tid: number;
    title: {
      lang_tid: number;
      val: string;
    }[];
    name: string;
    image?: {
      origin: string;
      thumb: string;
    };
    criteria: object[];
    notes: any[];
    replaces?: Replaces[],
    replaced_by?: Replaces[],
  };
  title: string;
  isFull: boolean;
  isDesktop?: boolean;
  filter: {
    items: object[];
    key: string;
    label: string;
  }[];
}> = ({ prefix, item, title, isFull, filter, isDesktop }) => {
  const { name, image } = item;
  const partLink = `${ prefix ? `/${prefix}` : ''}/part/${name}`;
  const specs = useMemo(() => {
    let specs = specificationsListByLang(item);

    if (item.notes?.length > 0) {
      // Add labelTid = key, for proper filtration
      specs = [...item.notes.map(note => ({ ...note, labelTid: note.id })), ...specs];
    }

    specs = [...specs, ...(overviewPartInfo(item) as any)];

    if (filter?.length > 0) {
      specs.forEach(specItem => {
        if (
          filter.some(
            filterItem =>
              specItem.labelTid && filterItem.key === specItem.labelTid,
          )
        ) {
          specItem.isActive = true;
        }
      });
    }

    // Separate by Active Filter Arr
    if (specs.length > 0) {
      specs = specs.reduce(
        ([activeArr, simpleArr], curr) => {
          return curr.isActive
            ? [[...activeArr, curr], simpleArr]
            : [activeArr, [...simpleArr, curr]];
        },
        [[], []],
      );

      specs = [...specs[0], ...specs[1]];
    }

    return specs;
  }, [filter]);
  const replacesElements = useMemo(() => {
    const { replaces, replaced_by } = item;
    const isBothAvailable = replaces.length > 0 && replaced_by.length > 0;

    if (isBothAvailable) {
      const postfixArray = [
        ...replaces.map(replacesItem => ({ ...replacesItem, name: `${replacesItem.name} (${ __('older') })` })),
        ...replaced_by.map(replacedItem => ({ ...replacedItem, name: `${replacedItem.name} (${ __('newer') })` })),
      ];

      return <PartsItemReplaces title={ __('Replacements') } items={postfixArray} />;
    }

    // Separated rows
    const resultPrefix = replaces.length > 0 ? __('Replaces') : __('Replaced by');
    const resultArray = [...replaces, ...replaced_by];
    const resultTitle = `${resultPrefix} ${resultArray.length > 1 ? __('PowerEdge part numbers') : __('PowerEdge part number')}`;

    return <PartsItemReplaces
      title={resultTitle}
      items={resultArray}
      isReplaced={replaced_by.length > 0}
    />;
  }, []);

  return (
    <div className={`parts-list__item ${!isFull ? 'parts-list__item_border-red' : ''}`}>
      <div className="parts-list__item-media">
        <picture
          className={cn('parts-list__item-media-picture', {
            'parts-list__item-media-picture_empty': !image?.thumb,
          })}
        >
          {image?.thumb ? (
            <img
              className="parts-list__item-media-img"
              src={image.thumb}
              alt={title}
              loading="lazy"
              decoding="async"
            />
          ) : (
            <Icon name="empty-image" className="parts-list__item-media-icon" />
          )}
        </picture>
      </div>
      <div className="parts-list__item-content">
        <div className="parts-list__item-sku">{title}</div>
        <Link className="heading heading_h3"
              style={{ textDecoration: 'none' }}
              to={ partLink }>
            PowerEdge {__('part number')}: {name}
        </Link>
        {specs.length > 0 ? (
          isFull ? (
            <div className="parts-list__item-specs">
              <Specs list={specs} isSmall />

              { replacesElements }
            </div>
          ) : (
            <>
              <div className="parts-list__item-short">
                <div className="parts-list__item-short-inner">
                  {specs.map(({ id, label, value, isActive }) => (
                    <div
                      key={id}
                      className={cn('parts-list__item-short-item', {
                        'parts-list__item-short-item_active': isActive,
                      })}
                    >
                      <div className="parts-list__item-short-item-key">
                        {label}:&nbsp;
                      </div>
                      <div className="parts-list__item-short-item-val">
                        {value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              { replacesElements }
            </>
          )
        ) : null}

        {
          isFull || !isDesktop
            ? (
              <div className="parts-list__item-foot">
                <Link
                  to={ partLink }
                  className="button button_outline button_small parts-list__item-foot-btn"
                >{ __('More information') }</Link>
              </div>
            )
            : null}
      </div>

      {
        !isFull && isDesktop
          ? (
            <div className="parts-list__item-arrow">
              <Link to={ partLink } className="parts-list__item-arrow-link">
                <Icon name="right-arrow" className="parts-list__item-arrow-svg" />
              </Link>
            </div>
          )
          : null}
    </div>
  );
};

export default PartsItem;
