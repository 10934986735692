import React, { FC, useEffect, useState } from 'react';
import Sidebar from './components/sidebar';
import Map from './components/map';
import { getUserLocation } from './helpers/location';
import storeUserLocation from './store/user-location';
import { Loader } from '@googlemaps/js-api-loader';
import { observer } from 'mobx-react-lite';
import { DESKTOP_WIDTH } from './constants';
import { getFilters } from './api';
import { setStoreFilters } from './helpers/store';
import CurrentVendor from './components/current-vendor';
import { useWindowSize } from './helpers/hooks';
import AddressField from './components/sidebar/components/address-field';

declare const window: any;

const App: FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const windowSize = useWindowSize();

  const mapLoader = new Loader({
    apiKey: window?.DENSO?.googleMapsApiKey,
    version: 'weekly',
    libraries: ['places'],
    language: window.DENSO?.languageHandle ?? 'en',
  });

  useEffect(() => {
    getFilters().then(filters => {
      setStoreFilters(filters);
    });
    mapLoader.load().then(() => {
      getUserLocation().then(position => {
        storeUserLocation.setPosition(position);
        setLoaded(true);
      });
    });
  }, []);

  return (
    <div className="where-to-buy">
      <Sidebar
        hideFilters={() => setMobileFiltersOpened(false)}
        showMobileFilters={mobileFiltersOpened}
      />
      {loaded && <Map />}
      <CurrentVendor />
      {windowSize.width < DESKTOP_WIDTH && <AddressField />}
      {windowSize.width < DESKTOP_WIDTH && (
        <div className="where-to-buy__sidebar-trigger">
          <button
            onClick={() => {
              setMobileFiltersOpened(!mobileFiltersOpened);
            }}
            className="button"
          >
            Filter
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(App);
