import car from './endpoints/car';
import altSearch from './endpoints/alt-search';
import auth from './endpoints/auth';
import account from './endpoints/account';

const api = {
  car,
  altSearch,
  auth,
  account,
};

export const apiRequest: (
  endPoint: string,
  method?: 'GET' | 'POST' | 'DELETE',
  params?: object,
  requestParams?: object,
) => Promise<Response> = (
  endPoint = '/',
  method = 'POST',
  params,
  requestParams,
): Promise<Response> => {
  const baseURl = '/api/v1';
  const langPrefix = (window as any).DENSO?.languageHandle ? `/${(window as any).DENSO.languageHandle}` : '';
  const body = method === 'POST'
    ? JSON.stringify(params)
    : null;

  return fetch(`${langPrefix}${baseURl}${endPoint}`, {
    method,
    body,
    ...requestParams,
  });
};

export default api;
