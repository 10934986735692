import { Store } from '../store/stores/model';

export function getShopCustomerType(store: Store): number {
  const customerTypes = [
    'customer_type_1',
    'customer_type_2',
    'customer_type_3'
  ];

  let customerType = 0;

  for (let [index, type] of customerTypes.entries()) {
    if (store[type]) {
      customerType = index;
      break;
    }
  }

  return customerType;
}

export function getMapsDirectionsLink(store: Store): string {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1&';
  return `${baseUrl}destination=${store.lat},${store.lng}`;
}
